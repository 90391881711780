// import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
// import BookCardTem from "../BookCardtem/BookCardTem";
// import { useNavigate } from "react-router-dom";
// import "../BookCardtem/BookCardTem.css"; // Import CSS file for custom styling
// import { MdDelete } from "react-icons/md";
// import { Dialog, DialogActions, DialogContent,} from "@mui/material";
// import axios from "axios";

// function RecentReadResponsive({ CardDetails, loading, showDeleteIcon}) { 
//     const navigate = useNavigate();
//     const [initialSlide, setInitialSlide] = useState(0);
//     const [openDialog, setOpenDialog] = useState(false);

//     useEffect(() => {
//         // Update initialSlide based on CardDetails length
//         if (CardDetails) {
//             const { length } = CardDetails;
//             setInitialSlide(Math.max(0, length - 4)); // Assuming slidesToShow is 4
//         }
//     }, [CardDetails]);

//     const handleBookClick = (bookData) => {
//         navigate('/BookDetails', { state: { data: bookData } });
//     };

//     const openRefundDialog = (event) => {
//         event.stopPropagation(); // Prevent the event from triggering navigation
//         setOpenDialog(true);
//     };

//     const closeRefundDialog = () => {
//         setOpenDialog(false);
//     };

//     const confirmRefund = async (id) => {
//         const checktoken = localStorage.getItem('token')
//         try {
//             const res = await axios.delete(`https://backend.mysecretlit.com/api/user/deleteReadBook?bookId=${id}`,{
//                 headers: {
//                     Authorization: `Bearer ${checktoken}`,
//                   },
//             });
//             if (res.status === 200) {
//                 window.location.reload()
//             } else {
//               console.log('Failed to delete book. Please try again.');
//             }
//           } catch (error) {
//             console.error('Error making API call:', error);
          
//           }
//         console.log('recendt' ,id)
//         closeRefundDialog();
//     };

//     const settings = {
//         dots: true,
//         infinite: false,
//         speed: 500,
//         slidesToShow: 5.5,
//         slidesToScroll: 4,
//         initialSlide: initialSlide,
//         beforeChange: (current, next) => {
//             const slidesToShow = settings.slidesToShow || 1;
//             const slideCount = Math.max(0, CardDetails.length - slidesToShow);
//             const transformValue = next === slideCount ? 0 : (slideCount - next) * (100 / slidesToShow);
//             document.querySelector('.slick-track').style.transform = `translate3d(-${transformValue}%, 0, 0)`; // Apply CSS transform
//         },
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 3.5,
//                     slidesToScroll: 3,
//                     infinite: true,
//                     dots: true
//                 }
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 3.5,
//                     slidesToScroll: 3,
//                     initialSlide: 0
//                 }
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 3.5,
//                     slidesToScroll: 3,
//                     initialSlide: 0
//                 }
//             }
//         ]
//     };

//     return (
//         <div className="slider-container">
//             <Slider {...settings} className="custom-slider">
//                 {CardDetails?.map((card, index) => (
//                     <div key={index} className="selectd-delete-btn" style={{ cursor: 'pointer' }} onClick={() => handleBookClick(card)}>
//                         {showDeleteIcon && (
//                             <MdDelete
//                                 className="delete-icon-book"
//                                 onClick={(event) => openRefundDialog(event)} // Trigger the dialog on delete icon click
//                             />
//                         )}
//                         <BookCardTem
//                             Bookname={card?.bookTitle}
//                             firstTopTag={card?.FirstTopTag}
//                             secTopTag={card?.SecTopTag}
//                             bookcategoriesFirst={card?.category?.name || 'unkown'}
//                             bookcategoriesSec={card?.secondaryCategory?.name || 'unkown'}
//                             rating={card?.rating}
//                             loading={loading}
//                             bookImg={card?.coverImage}
//                         />

//                         <Dialog
//                         style={{padding:'13px 24px'}}
//                             open={openDialog}
//                             onClose={(event) => { event.stopPropagation(); closeRefundDialog(); }} // Prevent navigating when closing dialog
//                             aria-labelledby="refund-dialog-title"
//                             aria-describedby="refund-dialog-description"
//                         >
//                             {/* <DialogTitle id="refund-dialog-title">Confirm Delete Recent Read book</DialogTitle> */}
//                             <DialogContent>
//                                 <p className='reviewDiv poupparagrgtext'>
//                                 Are you sure you want to delete this book from Recently Read ?
//                                 </p>
//                             </DialogContent>
//                             <DialogActions style={{display:'flex',justifyContent:'center',paddingBottom:34}}>
//                             <button className="btn-all-delete-popup" onClick={(event) => { event.stopPropagation(); confirmRefund(card._id); }} color="secondary" autoFocus>
//                                     Delete
//                                 </button>
//                                 <button  className="btn-all-delete-popup" onClick={(event) => { event.stopPropagation(); closeRefundDialog(); }} color="primary">
//                                     Cancel
//                                 </button>
                                
//                             </DialogActions>
//                         </Dialog>
//                     </div>
//                 ))}
//             </Slider>
//         </div>
//     );
// }

// export default RecentReadResponsive;
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import BookCardTem from "../BookCardtem/BookCardTem";
import { useNavigate } from "react-router-dom";
import "../BookCardtem/BookCardTem.css"; // Import CSS file for custom styling
import { MdDelete } from "react-icons/md";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import axios from "axios";

function RecentReadResponsive({ CardDetails, loading, showDeleteIcon }) {
    const navigate = useNavigate();
    const [initialSlide, setInitialSlide] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedBookId, setSelectedBookId] = useState(null);

    useEffect(() => {
        // Update initialSlide based on CardDetails length
        if (CardDetails) {
            const { length } = CardDetails;
            setInitialSlide(Math.max(0, length - 4)); // Assuming slidesToShow is 4
        }
    }, [CardDetails]);

    const handleBookClick = (bookData) => {
        navigate('/BookDetails', { state: { data: bookData } });
        // window.location.reload()
        window.scrollTo(0, 0);
    };

    const openRefundDialog = (event, bookId) => {
        event.stopPropagation(); // Prevent the event from triggering navigation
        setSelectedBookId(bookId);
        setOpenDialog(true);
    };

    const closeRefundDialog = () => {
        setOpenDialog(false);
        setSelectedBookId(null);
    };

    const confirmRefund = async () => {
        const checktoken = localStorage.getItem('token');
        try {
            if (selectedBookId) {
                const res = await axios.delete(`https://backend.mysecretlit.com/api/user/deleteReadBook?bookId=${selectedBookId}`, {
                    headers: {
                        Authorization: `Bearer ${checktoken}`,
                    },
                });
                if (res.status === 200) {
                    window.location.reload();
                } else {
                    console.log('Failed to delete book. Please try again.');
                }
            }
        } catch (error) {
            console.error('Error making API call:', error);
        }
        closeRefundDialog();
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 200,
        slidesToShow: 5.5,
        slidesToScroll: 4,
        initialSlide: initialSlide,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3.5,
                    slidesToScroll: 3,
                }
            }
        ]
    };
const HeartFuntion = async(item)=>{
const checktoken = localStorage.getItem('token');
try {
    if (item) {
        const res = await axios.post('https://backend.mysecretlit.com/api/user/favBook',{bookId:item}, {
            headers: {
                Authorization: `Bearer ${checktoken}`,
            },
        });
        if (res.status === 200) {
           
        } else {
            console.log('Failed to delete book. Please try again.');
        }
    }
} catch (error) {
    console.error('Error making API call:', error);
}
}
    return (
        <div className="slider-container">
            <Slider {...settings} className="custom-slider">
                {CardDetails?.map((card, index) => (
                    <div key={index} className="selectd-delete-btn" style={{ cursor: 'pointer' }} onClick={() => handleBookClick(card)}>
                        {showDeleteIcon && (
                            <MdDelete
                                className="delete-icon-book"
                                onClick={(event) => openRefundDialog(event, card._id)} // Pass the specific book ID
                            />
                        )}
                        <BookCardTem
                        onHeartClick={()=>HeartFuntion(card?._id)}
                            Bookname={card?.bookTitle}
                            firstTopTag={card?.FirstTopTag}
                            secTopTag={card?.SecTopTag}
                            bookcategoriesFirst={card?.category?.name || 'unknown'}
                            bookcategoriesSec={card?.secondaryCategory?.name || 'unknown'}
                            rating={card?.rating}
                            loading={loading}
                            bookImg={card?.coverImage}
                        />
                    </div>
                ))}
            </Slider>

            {/* Dialog for deletion confirmation */}
            <Dialog
                style={{ padding: '13px 24px',border:'1p solid black' }}
                open={openDialog}
                onClose={(event) => { event.stopPropagation(); closeRefundDialog(); }} // Prevent navigating when closing dialog
                aria-labelledby="refund-dialog-title"
                aria-describedby="refund-dialog-description"
            >
                <DialogContent style={{backgroundColor:'black'}}>
                    <p className='reviewDiv poupparagrgtext'>
                        Are you sure you want to delete this book from Recently Read?
                    </p>
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'center', paddingBottom: 34,backgroundColor:'black' }}>
                    <button className="btn-all-delete-popup" onClick={(event) => { event.stopPropagation(); confirmRefund(); }} color="secondary" autoFocus>
                        Delete
                    </button>
                    <button className="btn-all-delete-popup" onClick={(event) => { event.stopPropagation(); closeRefundDialog(); }} color="primary">
                        Cancel
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default RecentReadResponsive;
